import React, { useContext } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import { ColorModeContext } from "./../theme";
import {
  DarkModeOutlined,
  LightModeOutlined,
  LogoutOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";



function Topbar() {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const router = useNavigate()
  return (
    <Box display="flex" justifyContent="flex-end" width="100%"
      sx={{
        padding: "12px 8px 12px 8px",
        borderBottom: "1px solid #8d0006",
      }}
    >

      {/* Box => display="flex" gap="20px" */}
      <Box> 

        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlined />
          ) : (
            <LightModeOutlined />
          )}
        </IconButton> */}

        <div class="dropdown">
          <a class="btn btn-secondary  dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
            <IconButton >
              <PersonOutlineOutlined />
            </IconButton>
          </a>

          <ul class="dropdown-menu drop-padding" aria-labelledby="dropdownMenuLink">
            <IconButton
              onClick={() => {
                sessionStorage.removeItem("admin");
                window.location.reload("/");
                // router('/')
              }}
            >
              <p style={{marginBottom: "0px", fontSize: "16px"}}>Logout</p>
              <LogoutOutlined />
            </IconButton> 
          </ul>
        </div>
        
        
        
      </Box>
    </Box>
  );
}

export default Topbar;
