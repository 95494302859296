import { Box } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DELETE, GET, REMOVE } from "../Functions/apiFunction";
import Skeleton from "@mui/material/Skeleton";


function Dashboards({dashConfig}) {  



  return(<>

    {
      dashConfig  ?
    <div class="row"  style={{marginTop: "30px"}}>
      <div className="col-xl-3 col-lg-4 col-md-6" style={{marginBottom: "20px"}}>
        <Box sx={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          border: "none",
          borderRadius: "10px",
          padding: "15px",
          height: "100%",
          minHeight: "170px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
          <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <div>
              <h3 style={{fontWeight:"600", fontSize: "24px"}}>Today Orders</h3>
            </div>
            <div className="dashboard-icon" style={{marginLeft: "10px"}}>
              <i class="fas fa-folder"></i>
            </div>
          </Box>
          <div style={{marginTop: "10px"}}>
            <h3 style={{fontWeight:"600", fontSize: "24px"}}>{dashConfig?.today_order ?? ''}</h3>
          </div>
        </Box>
      </div>

      <div className="col-xl-3 col-lg-4 col-md-6" style={{marginBottom: "20px"}}>
        <Box sx={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          border: "none",
          borderRadius: "10px",
          padding: "15px",
          height: "100%",
          minHeight: "170px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
          <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <div>
              <h3 style={{fontWeight:"600", fontSize: "24px"}}>Total Orders</h3>
            </div>
            <div className="dashboard-icon" style={{marginLeft: "10px"}}>
              <i class="fas fa-folder"></i>
            </div>
          </Box>
          <div style={{marginTop: "10px"}}>
            <h3 style={{fontWeight:"600", fontSize: "24px"}}>{dashConfig?.total_order ?? ''}</h3>
          </div>
        </Box>
      </div>

      <div className="col-xl-3 col-lg-4 col-md-6" style={{marginBottom: "20px"}}>
        <Box sx={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          border: "none",
          borderRadius: "10px",
          padding: "15px",
          height: "100%",
          minHeight: "170px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
          <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <div>
              <h3 style={{fontWeight:"600", fontSize: "24px"}}>Total Subscription</h3>
            </div>
            <div className="dashboard-icon" style={{marginLeft: "10px"}}>
              <i class="fas fa-folder"></i>
            </div>
          </Box>
          <div style={{marginTop: "10px"}}>
            <h3 style={{fontWeight:"600", fontSize: "24px"}}>{dashConfig?.total_sub ?? ''}</h3>
          </div>
        </Box>
      </div>

      <div className="col-xl-3 col-lg-4 col-md-6" style={{marginBottom: "20px"}}>
        <Box sx={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          border: "none",
          borderRadius: "10px",
          padding: "15px",
          height: "100%",
          minHeight: "170px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
          <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <div>
              <h3 style={{fontWeight:"600", fontSize: "24px"}}>Upcoming Subscription</h3>
            </div>
            <div className="dashboard-icon" style={{marginLeft: "10px"}}>
              <i class="fas fa-folder"></i>
            </div>
          </Box>
          <div style={{marginTop: "10px"}}>
            <h3 style={{fontWeight:"600", fontSize: "24px"}}>{dashConfig?.upcoming_sub ?? ''}</h3>
          </div>
        </Box>
      </div>

      <div className="col-xl-3 col-lg-4 col-md-6" style={{marginBottom: "20px"}}>
        <Box sx={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          border: "none",
          borderRadius: "10px",
          padding: "15px",
          height: "100%",
          minHeight: "170px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
          <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <div>
              <h3 style={{fontWeight:"600", fontSize: "24px"}}>Upcoming Orders</h3>
            </div>
            <div className="dashboard-icon" style={{marginLeft: "10px"}}>
              <i class="fas fa-folder"></i>
            </div>
          </Box>
          <div style={{marginTop: "10px"}}>
            <h3 style={{fontWeight:"600", fontSize: "24px"}}>{dashConfig?.upcoming_order ?? ''}</h3>
          </div>
        </Box>
      </div>

      <div className="col-xl-3 col-lg-4 col-md-6" style={{marginBottom: "20px"}}>
        <Box sx={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          border: "none",
          borderRadius: "10px",
          padding: "15px",
          height: "100%",
          minHeight: "170px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
          <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <div>
              <h3 style={{fontWeight:"600", fontSize: "24px"}}>Total Users</h3>
            </div>
            <div className="dashboard-icon" style={{marginLeft: "10px"}}>
              <i class="fas fa-folder"></i>
            </div>
          </Box>
          <div style={{marginTop: "10px"}}>
            <h3 style={{fontWeight:"600", fontSize: "24px"}}>{dashConfig?.user ?? ''}</h3>
          </div>
        </Box>
      </div>

      <div className="col-xl-3 col-lg-4 col-md-6" style={{marginBottom: "20px"}}>
        <Box sx={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          border: "none",
          borderRadius: "10px",
          padding: "15px",
          height: "100%",
          minHeight: "170px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
          <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <div>
              <h3 style={{fontWeight:"600", fontSize: "24px"}}>Total Categories</h3>
            </div>
            <div className="dashboard-icon" style={{marginLeft: "10px"}}>
              <i class="fas fa-folder"></i>
            </div>
          </Box>
          <div style={{marginTop: "10px"}}>
            <h3 style={{fontWeight:"600", fontSize: "24px"}}>{dashConfig?.category ?? ''}</h3>
          </div>
        </Box>
      </div>

      <div className="col-xl-3 col-lg-4 col-md-6" style={{marginBottom: "20px"}}>
        <Box sx={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          border: "none",
          borderRadius: "10px",
          padding: "15px",
          height: "100%",
          minHeight: "170px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
          <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <div>
              <h3 style={{fontWeight:"600", fontSize: "24px"}}>Total Products</h3>
            </div>
            <div className="dashboard-icon" style={{marginLeft: "10px"}}>
              <i class="fas fa-folder"></i>
            </div>
          </Box>
          <div style={{marginTop: "10px"}}>
            <h3 style={{fontWeight:"600", fontSize: "24px"}}>{dashConfig?.product ?? ''}</h3>
          </div>
        </Box>
      </div>
    </div> :    
     <div class="row"  style={{marginTop: "30px"}}> 
     {
      Array.from({length : 8}).map((_,i) => (
        <div className="col-xl-3 col-lg-4 col-md-6" style={{marginBottom: "20px"}}>
          <Box sx={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            border: "none",
            borderRadius: "10px",
            padding: "15px",
            height: "100%",
            minHeight: "170px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          }}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}>
              <div>
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"140px"}
                height={30}
              />
              </div>
              <div className="dashboard-icon" style={{marginLeft: "10px"}}>
                {/* <i class="fas fa-folder"></i> */}
                <Skeleton
                variant="rectangular"
                animation="wave"
                width={30}
                height={30}
              />
              </div>
            </Box>
            <div style={{marginTop: "10px"}}>
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
            </div>
          </Box>
        </div>
      ))
     }

    </div>
    }
    
    

  </>)
  
}

export default Dashboards;
