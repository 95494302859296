import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
  } from "@mui/x-data-grid";
  import {
    Select,
    TextField,
    Typography,
    useTheme,
    MenuItem,
    CircularProgress,
    Snackbar,
    Alert,
  } from "@mui/material";
  import Box from "@mui/material/Box";
  import moment from "moment/moment";
import { Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import { DELETE, GET, REMOVE } from "../Functions/apiFunction";
import api from "../Data/api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tokens } from "../theme";
import image from "./../Data/image";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";


function Slots() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [Slots, setSlots] = useState([]);
    const [mainSlots, setMainSlots] = useState([]);
    const [pageSize, setpageSize] = useState(20);
    const handleSnakBarOpen = () => setsnakbarOpen(true);
    const handleSnakBarClose = () => setsnakbarOpen(false); 
    const [snakbarOpen, setsnakbarOpen] = useState(false);
    const [alertType, setalertType] = useState("");
    const [alertMsg, setalertMsg] = useState("");

    const dispatch = useDispatch();
  
    const admin = JSON.parse(sessionStorage.getItem("admin"));

    const token = `Bearer ${admin.token}`;

    const status = (id) => {
      const data = [
        {
          id: 1,
          text: "Enable",
        },
        {
          id: 0,
          text: "Disable",
        },
      ];
      const ttl = data.filter((dt) => dt.id === id);
      return ttl[0].text;
    }; 

     const sessionSlots = (id) => {
      const data = [
        {
          id: 1,
          text: "Morning",
        },
        {
          id: 2,
          text: "Evening",
        },
      ];
      const ttl = data.filter((dt) => dt.id === id);
      return ttl[0]?.text;
    }; 

    // Get categoriues
    const getCat = async () => { 
      try {
        const url = `${api}/slot_list`;
        const response = await GET(token, url);
        setSlots(response?.data ?? []);
        setMainSlots(response?.data);
      } catch (error) {
        console.log('err', error.message)
      }
    };
  
    useEffect(() => {
      getCat();
    }, [token, dispatch]);   
    
    
    const handleRemove  = async (id) => {
      try {
        const url = `${api}/delete_slot/${id}`;
        const response = await REMOVE(token, url, {});
        if(response){
          handleSnakBarOpen();
          setalertType('success');
          setalertMsg(response.message ?? "Deleted!");
          getCat(); 
        }
      } catch (error) {
        console.log('err', error.message)
      }
    }




  
    const column = useMemo(
      () => [
        { field: "id", headerName: "Id", width: 100 },
        { field: "slot_session", headerName: "Slot Session", width: 250,
          renderCell: (params) => <p>{sessionSlots(params?.row?.slot_session)}</p>,
        },
        { field: "slot_time", headerName: "Slot Time", width: 250 },
        {
            field: "status",
            headerName: "Status",
            width: 250,
            renderCell: (params) => <p>{status(params?.row?.status)}</p>,
        },
        // {
        //   field: "updated_at",
        //   headerName: "Last Update",
        //   width: 250,
        //   renderCell: (params) =>
        //     moment(params.row.updated_at).format("DD-MM-YYYY HH:MM:SS"),
        // },
        {
          field: "Edit",
          headerName: "Edit",
          width: 200,
          renderCell: (params) => (
            <button
              class="updateBtn"
              onClick={() => {
                // navigate(`/Slots/${params.row.id}`);
                navigate(`/SlotsForm`,{state:{id:params.row.id}});
              }}
            >
               <i class="fa-regular fa-eye"></i>
            </button>
          ),
        },

        {
            field: "Delete",
            headerName: "Delete",
            width: 250,
            renderCell: (params) => (
              <button
                class="updateBtn"
                onClick={() => { 
                  handleRemove(params.row.id)
                }}
              >
               <i class="fa fa-trash fs-7" aria-hidden="true"></i>
              </button>
            ),
          },
      ],
      [navigate]
    );
  
    // custom toolbar
    function CustomToolbar() {
      return (
        <GridToolbarContainer
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <div>
            {" "}
            <GridToolbarExport color="secondary" sx={{ fontSize: "14px" }} />
            <Select
              sx={{
                width: "100px",
                height: "30px",
                marginLeft: "20px",
              }}
              color="primary"
              size="small"
              labelId="demo-select-small"
              id="demo-select-small"
              value={pageSize}
              label="Page Size"
              onChange={(e) => {
                setpageSize(e.target.value);
              }}
              className="TopPageBar"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </div>
           
          <button
            class="cssbuttons-io-button"
            onClick={() => {
              navigate("/SlotsForm");
            }}
          >
            {" "}
            Add Slot
            <div class="icon">
              <i class="fa-regular fa-plus"></i>
            </div>
          </button>
        </GridToolbarContainer>
      );
    }
    return (
      <div> 
      <Snackbar
        open={snakbarOpen}
        autoHideDuration={3000}
        onClose={handleSnakBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnakBarClose}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>

        <Box sx={{ height: 600, width: "100%" }}>
          <div 
          className="mt-4"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row-reverse",
            }}
          >
            {" "}
            <Typography variant="h3" component={"h3"} sx={{ textAlign: "left" }}>
              Manage Slots
            </Typography> 

            {/* <TextField
              margin="normal"
              size="small"
              sx={{ width: { xs: "80%", sm: "300px", md: "500px" } }}
              id="Search"
              label="Search"
              name="Search"
              color="secondary"
              onChange={(e) => {
                e.preventDefault();
                setTimeout(() => {
                  function searchArrayByValue(arr, searchQuery) {
                    return arr.filter((obj) => {
                      return Object.values(obj).some((val) => {
                        if (typeof val === "string") {
                          return val
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase());
                        }
                        if (typeof val === "number") {
                          return val
                            .toString()
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase());
                        }
                        return false;
                      });
                    });
                  }
                  setSlots(
                    searchArrayByValue(mainSlots, e.target.value.toLowerCase())
                  );
                }, 500);
              }}
            /> */}
          </div>

          {Slots ? (
            <Box
              sx={{ 
                marginTop:"20px",
                width: "100%",
                height: "100%",
                paddingBottom: "30px",
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-row": {
                  fontSize: "14px",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.navbarBG[400],
                  borderBottom: "1px solid #383838",
                  color: "#000",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                  borderBottom: "#000",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "1px solid #383838",
                  backgroundColor: colors.navbarBG[400],
                  color: "#f5f5f5 !important",
                },
                "& .MuiTablePagination-root": {
                  color: "#000 !important",
                },
                "& .MuiTablePagination-selectIcon": {
                  color: "#f5f5f5 !important",
                },
                "& .MuiTablePagination-actions botton": {
                  color: "#f5f5f5 !important",
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiTablePagination-selectLabel" : {
                  marginBottom : "0px !important"
                },
                "& .MuiTablePagination-displayedRows" : {
                  marginBottom : "0px !important"
                }
              }}
            >
              {" "}
              <DataGrid
                sx={{ fontSize: "13px" }}
                columns={column}
                rows={Slots}
                // page={false}
                // hideFooter
                components={{ Toolbar: CustomToolbar }}
                rowsPerPageOptions={[10, 20, 25, 50, 100]}
                // rowsPerPageOptions={[]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
              />
            </Box>
          ) :  (
            <Stack spacing={1}>
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              {/* For other variants, adjust the size with `width` and `height` */}
  
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
            </Stack>
          )}
        </Box>
      </div>
    );
  }
  

export default Slots;
