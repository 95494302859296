import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import {
  Button,
  Autocomplete,
  CircularProgress,
  Snackbar,
  Alert,
  // Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Stack,
  Divider,
  Input,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import "../Styles/product.css";
import TextField from "@mui/material/TextField";
import api from "../Data/api";
import { ADD, GET } from "../Functions/apiFunction";
import { tokens } from "../theme";
import moment from "moment";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Select from "react-select";

function Slotsform() {
  const theme = useTheme();
  const [status, setstatus] = useState();
  const [Session, setSession] = useState();
  const [slotsTime, setslotsTime] = useState();

  const navigate = useNavigate();

  const [LOADING, setLOADING] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const colors = tokens(theme.palette.mode);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;
  const [snakbarOpen, setsnakbarOpen] = useState(false);
  const [alertType, setalertType] = useState("");
  const [alertMsg, setalertMsg] = useState("");

  const param = useParams(); 

  const {state} = useLocation();

  const handleSnakBarOpen = () => setsnakbarOpen(true);
  const handleSnakBarClose = () => setsnakbarOpen(false); 

  const [SlotDataValue,setDataValue] = useState();

  const updateSlots = async (e) => {
    e.preventDefault();
    setLOADING(true)
    const SlotData = {
      slot_session: Session,
      slot_time: slotsTime,
      status: status,
    };
    // let url = `${api}/update_slot/${param?.id}`;
    let url = state?.id ? `${api}/update_slot/${state?.id}` : `${api}/add_slot`;
    const response = await ADD(token, url, SlotData);
    setLOADING(false)
    handleSnakBarOpen();
    setalertType("success");
    setalertMsg(response.message);
    setTimeout(() => {
      navigate("/Slots");
    }, 1000);
  };

  // https://api.mc-food.vrikshatech.in/api/get_slot/3

  const fetchdata = async () => {   
    try {     
      setIsloading(true)
      if(!state?.id) {
        navigate("/Slots");
      }
      // let url = `${api}/get_slot/${param?.id}`;
      let url = `${api}/get_slot/${state?.id}`;
      const response = await GET(token, url);
     
      if (response.status) { 
        setDataValue(response?.data);
        setstatus(response?.data?.status);
        setslotsTime(response?.data?.slot_time);
        setSession(response?.data?.slot_session);
        setIsloading(false)
      }
    } catch (error) { 
      console.log('err',error.message)
      setIsloading(true)
    }
  };
  useEffect(() => {
    if(state?.id) {
      fetchdata();
    } 
    
  // }, [param?.id, token]);
}, [state?.id, token]);


  return (
    <>
      <Snackbar
        open={snakbarOpen}
        autoHideDuration={3000}
        onClose={handleSnakBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnakBarClose}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
          borderBottom: colors.grey[300],
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => {
              navigate("/Slots");
            }}
          >
            <ArrowBackIcon />
          </IconButton>{" "}
          <h2 className="heading"> {state?.id ?  "Update Slots" : "Add Slots"}</h2>
        </div>
      </Box> 
      {
         !isLoading ? 
      <Box component="form" onSubmit={updateSlots}>
        <div className="product">
          <div
            className="left"
            style={{
              backgroundColor: colors.cardBG[400],
            }}
          >
            <h2>Slots Details</h2>
            <p>
              Enter the required information below . {state?.id ?  `You can change it anytime
              you want.` : '' }
            </p>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"space-between"}
              gap="20px"
              mt="30px"
            >
              <Box flex={1}>
                {" "}
                {/* <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    color="secondary"
                    size="small"
                  >
                    Slot Session
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Session"
                    size="small"
                    fullWidth
                    color="secondary"
                    value={Session}
                    onChange={(e) => {
                      setSession(e.target.value);
                    }}
                  >
                    <MenuItem value={1}>Morning</MenuItem>
                    <MenuItem value={0}>Evening</MenuItem>
                  </Select>
                </FormControl> */}
                <Select
                  isSearchable={false}
                  placeholder="Select Slot Session"
                  classNamePrefix="select"
                  inputId="Career_Goal"
                  value={[
                    { label: "Morning", value: 1 },
                    { label: "Evening", value: 2 },
                  ].find((e) => e.value == Session)}
                  options={[
                    { label: "Morning", value: 1 },
                    { label: "Evening", value: 2 },
                  ]}
                  getOptionLabel={(e) => e?.label}
                  getOptionValue={(e) => e?.value}
                  onChange={(v) => {
                    setSession(v.value);
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#cc232abd",
                      primary: "#CC232A",
                      primary50: "#cc232abd",
                    },
                  })}
                />
              </Box>
              {/* Select */}
              <Box flex={1}>
                {" "}
                <Select
                  isSearchable={false}
                  placeholder="Select Status"
                  classNamePrefix="select"
                  inputId="Career_Goal"
                  value={[
                    { label: "Enable", value: 1 },
                    { label: "Disable", value: 0 },
                  ].find((e) => e.value == status) ?? ''}
                  options={[
                    { label: "Enable", value: 1 },
                    { label: "Disable", value: 0 },
                  ]}
                  getOptionLabel={(e) => e?.label}
                  getOptionValue={(e) => e?.value}
                  onChange={(v) => {
                    setstatus(v.value);
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#e712127a",
                      primary: "#CC232A",
                      primary50: "#e712127a",
                    },
                  })}
                />
              </Box>

              <Box flex={1}>
                {" "}
                <FormControl fullWidth>
                  {/* <Input type="text" className="" placeholder="Enter Slot Time" />  */}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="Slot_Time"
                    label=""
                    placeholder="Enter Slot Time"
                    name="Slot_Time"
                    type="text"
                    color="secondary"
                    autoComplete="number"
                    className="mb-3"
                    size="small"
                    value={slotsTime}
                    onChange={(e) => setslotsTime(e.target.value)}
                  />
                </FormControl>
              </Box>
            </Box>
          </div>
        </div>

        <div className="delete">
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="secondary"
            sx={{ fontWeight: "600", letterSpacing: "1px" }}
          >
            {LOADING ? <CircularProgress size={20} /> : state?.id ? "Update Slot" : "Add Slot"}
          </Button>
        </div>
      </Box> :  (
        <Stack spacing={1}>
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          {/* For other variants, adjust the size with `width` and `height` */}

          <Skeleton
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={30}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={30}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={30}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={30}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={30}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={30}
          />
        </Stack>
      )
      }
    </>
  );
}

export default Slotsform;
