import React from "react";
import {
  Sidebar as ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
  sidebarClasses,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { tokens } from "../theme";
import CategoryIcon from "@mui/icons-material/Category";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import GroupIcon from "@mui/icons-material/Group";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Button, IconButton, Typography, useMediaQuery } from "@mui/material";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import PaymentIcon from "@mui/icons-material/Payment";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import InfoIcon from "@mui/icons-material/Info";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import RateReviewIcon from "@mui/icons-material/RateReview";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import NextWeekIcon from "@mui/icons-material/NextWeek";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import "../Styles/sidebar.css";
import { AccountBalanceWallet } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const Links = ({ title, to, icon, location, collapsed, submenu }) => {
  return (
    <>
      {submenu?.length > 0 ?
        <SubMenu label={title} icon={icon}>
          {
            submenu.map((menu,i) => {
              return (
                <Link to={menu.to} style={{ all: "unset" }} key={i}>
                <MenuItem
                  style={collapsed ? { paddingLeft: "20%", backgroundColor: "#8d0006" } : { paddingLeft: "10%", backgroundColor: "#8d0006" }}
                  active={menu.to === location}
                  icon={menu.icon}
                >
                  <Typography variant="h5">{menu.name}</Typography>
                </MenuItem>
                </Link>
              )
            })
          }
        </SubMenu>
        :
        <Link to={to} style={{ all: "unset" }}>
        <MenuItem
          style={collapsed ? { paddingLeft: "20%" } : { paddingLeft: "10%" }}
          active={to === location}
          icon={icon}
        >
          <Typography variant="h5">{title}</Typography>
        </MenuItem>
        </Link>
      }

    </>
  );
};

function Sidebar() {
  const Uselocation = useLocation();
  const location = Uselocation.pathname.replace("/", "");
  const matches = useMediaQuery("(max-width:767px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { collapseSidebar, collapsed } = useProSidebar();
  const admin = JSON.parse(sessionStorage.getItem("admin"));

  const links = [
    {
      name: "Dashboard",
      to: "/",
      icon: function () {
        return <i className="fas fa-home"></i>;
      },
    },
    // {
    //   name: "Delivery Report",
    //   to: "DeliveryReport",
    //   icon: function () {
    //     return <QueryStatsIcon />;
    //   },
    // },
    // {
    //   name: "Upcoming Orders",
    //   to: "upcoming-orders",
    //   icon: function () {
    //     return <NextWeekIcon />;
    //   },
    // },
    // {
    //   name: "Upcoming Subs",
    //   to: "upcoming-subs-orders",
    //   icon: function () {
    //     return <ContentPasteGoIcon />;
    //   },
    // },

    {
      name: "Manage Products",
      to: "ManageProduct",
      icon: function () {
        return <SubtitlesIcon />;
      },
      subMenu: [
        // {
        //   name: "Categories",
        //   to: "Categories",
        //   icon: <CategoryIcon />
        // },
        {
          name: "Categories",
          to: "Subcategory",
          icon:<SubtitlesIcon />,
        },
        {
          name: "Products",
          to: "Products",
          icon: <ArtTrackIcon />,
        },
      ]
    },

    // {
    //   name: "Categories",
    //   to: "Categories",
    //   icon: function () {
    //     return <CategoryIcon />;
    //   },
    // },

    // {
    //   name: "Subcategory",
    //   to: "Subcategory",
    //   icon: function () {
    //     return <SubtitlesIcon />;
    //   },
    // },

    // {
    //   name: "Products",
    //   to: "Products",  
    //   icon: function () {
    //     return <ArtTrackIcon />;
    //   },
    // },

    {
      name: "Manage User",
      to: "ManageUser",
      icon: function () {
        return <i className="fa-solid fa-users"></i>;
      },
      subMenu: [
        {
          name: "Users",
          to: "Users",
          icon:  <GroupIcon />,
        },
      ]
    },

    // {
    //   name: "Users",
    //   to: "Users",
    //   icon: function () {
    //     return <GroupIcon />;
    //   },
    // },
    // {
    //   name: "Drivers",
    //   to: "Drivers",
    //   icon: function () {
    //     return <DirectionsBikeIcon />;
    //   },
    // },
    {
      name: "Manage Order",
      to: "ManageOrder",
      icon: function () {
        return <i className="fa-regular fa-folder-open"></i>;
      },
      subMenu: [
        {
          name: "Orders",
          to: "Orders",
          icon: <LocalMallIcon />,
        }, 
         {
          name: "Slots",
          to: "Slots",
          icon: <AccessTimeFilledIcon />,
        },
        {
          name: "Transaction",
          to: "Transaction",
          icon: <PaymentIcon />,
        },
        {
          name: "Delivery Report",
          to: "DeliveryReport",
          icon: <QueryStatsIcon />,
        },
        {
          name: "Upcoming Orders",
          to: "upcoming-orders",
          icon: <NextWeekIcon />,
        },
        {
          name: "Upcoming Subs",
          to: "upcoming-subs-orders",
          icon: <ContentPasteGoIcon />,
        },
      ]
    },

    // {
    //   name: "Orders",
    //   to: "Orders",
    //   icon: function () {
    //     return <LocalMallIcon />;
    //   },
    // },

    // {
    //   name: "Transaction",
    //   to: "Transaction",
    //   icon: function () {
    //     return <PaymentIcon />;
    //   },
    // },

    {
      name: "Payment Setting",
      to: "PaySetting",
      icon: function () {
        return <i className="fa-regular fa-credit-card"></i>;
      },
      subMenu: [
        {
          name: "Invoice Setting",
          to: "invoice-setting",
          icon: <DescriptionIcon />,
        },
        {
          name: "Payment Getway",
          to: "payment-getway",
          icon: <i className="fa-brands fa-paypal"></i>,
        },
      ]
    },
    // {
    //   name: "Report",
    //   to: "Reports",
    //   icon: function () {
    //     return <i className="fa-regular fa-credit-card"></i>;
    //   },
    //   subMenu: [
    //     {
    //       name: "Daily Report",
    //       to: "#",
    //       icon: <DescriptionIcon />,
    //     },
    //     {
    //       name: "Monthly Report",
    //       to: "#",
    //       icon: <i className="fa-regular fa-credit-card"></i>,
    //     },
    //     {
    //       name: "Item-Wise Report",
    //       to: "#",
    //       icon: <i className="fa-regular fa-credit-card"></i>,
    //     },
    //   ]
    // },

    {
      name: "Website Setting",
      to: "webSetting",
      icon: function () {
        return <i className="fa-solid fa-globe"></i>;
      },
      subMenu: [
        {
          name: "Banners",
          to: "Banners",
          icon: <ViewCarouselIcon />,
        },
        {
          name: "About-Us",
          to: "About-Us",
          icon: <InfoIcon />,
        },
        {
          name: "Privacy",
          to: "Privicy",
          icon: <ManageAccountsIcon />,
        },
        {
          name: "Terms",
          to: "Terms",
          icon: <LibraryBooksIcon />,
        },
        {
          name: "Pincode",
          to: "Pincode",
          icon: <AddLocationAltIcon />,
        },
        {
          name: "Testimonial",
          to: "Testimonial",
          icon: <RateReviewIcon />,
        },
        {
          name: "Notification",
          to: "Notification",
          icon: <NotificationsActiveIcon />,
        },
        // {
        //   name: "Delivery Location",
        //   to: "delivery-location",
        //   icon: <i className="fa-solid fa-street-view"></i>,
        // },
        // {
        //   name: "Social Media",
        //   to: "social-media",
        //   icon: <i className="fa-regular fa-thumbs-up"></i>,
        // },
      ]
    },




    // {
    //   name: "Banners",
    //   to: "Banners",
    //   icon: function () {
    //     return <ViewCarouselIcon />;
    //   },
    // },
    // {
    //   name: "About-Us",
    //   to: "About-Us",
    //   icon: function () {
    //     return <InfoIcon />;
    //   },
    // },
    // {
    //   name: "Privicy",
    //   to: "Privicy",
    //   icon: function () {
    //     return <ManageAccountsIcon />;
    //   },
    // },
    // {
    //   name: "Terms",
    //   to: "Terms",
    //   icon: function () {
    //     return <LibraryBooksIcon />;
    //   },
    // },
    // {
    //   name: "Pincode",
    //   to: "Pincode",
    //   icon: function () {
    //     return <AddLocationAltIcon />;
    //   },
    // },
    // {
    //   name: "Testimonial",
    //   to: "Testimonial",
    //   icon: function () {
    //     return <RateReviewIcon />;
    //   },
    // },

    // {
    //   name: "Setting",
    //   to: "Setting",
    //   icon: function () {
    //     return <SettingsIcon />;
    //   },
    // },

    // {
    //   name: "Notification",
    //   to: "Notification",
    //   icon: function () {
    //     return <NotificationsActiveIcon />;
    //   },
    // },

    // {
    //   name: "Low Wallet Notification",
    //   to: "Low-Wallet-Notification",
    //   icon: function () {
    //     return <AccountBalanceWallet />;
    //   },
    // },
    {
      name: "Web App Setting",
      to: "web-app-setting",
      icon: function () {
        return <i className="fa-brands fa-chrome"></i>;
      },
    },
    // {
    //   name: "Invoice Setting",
    //   to: "invoice-setting",
    //   icon: function () {
    //     return <DescriptionIcon />;
    //   },
    // },
    // {
    //   name: "Delivery Location",
    //   to: "delivery-location",
    //   icon: function () {
    //     return <i className="fa-solid fa-street-view"></i>;
    //   },
    // },
    // {
    //   name: "Payment Getway",
    //   to: "payment-getway",
    //   icon: function () {
    //     return <i className="fa-brands fa-paypal"></i>;
    //   },
    // },
    {
      name: "Social Media",
      to: "social-media",
      icon: function () {
        return <i className="fa-regular fa-thumbs-up"></i>;
      },
    },

  ];

  return (
    <div className="sideBar">
      <Box height={"100vh"} 
        sx={{borderRight:"1px solid #8d0006"}}
      >
        <ProSidebar
          defaultCollapsed={matches}
          backgroundColor={"#8d0006"}
          height={"100vh"}
          paddingBottom={"50px"}
          rootStyles={{
            [`.${sidebarClasses.container}`]: {
              height: "100vh",
            },
            [`.${sidebarClasses.root}`]: {
              borderRight: "none !important",
            },
          }}
        >
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    height: "40px",
                    color: disabled ? "#f5d9ff" : "#d359ff",
                    backgroundColor: active ? "#6870fa" : undefined,
                    "&:hover": {
                      backgroundColor: "transparent !important",
                    },
                  };
              },
            }}
          >
            <MenuItem
              onClick={() => collapseSidebar()}
              style={{
                margin: "10px 0 0 0",
                color: "#fff !important",
              }}
            >
              {collapsed ? (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  color="#fff !important"
                >
                  <Button>
                  <IconButton onClick={() => collapseSidebar()}>
                    <MenuOutlinedIcon
                      onClick={() => collapseSidebar()}
                      color="sideText"
                    />
                  </IconButton>
                  </Button>
                </Box>
              ) : (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="10px"
                >
                  {/* <Typography variant="h3" color={"#FFF"}>
                    MC Food App
                  </Typography> */}

                  <img src="/assets/mc-logo-2.png" alt="Logo" width={"100px"}/>
                  <Button>
                  <IconButton onClick={() => collapseSidebar()}>
                    <MenuOutlinedIcon
                      onClick={() => collapseSidebar()}
                      color="sideText"
                    />
                  </IconButton>
                  </Button>
                </Box>
              )}
            </MenuItem>
          </Menu>

          <Menu
            iconShape="square"
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    height: "50px",
                    color: active
                      ? `${colors.selectedText[100]} !important`
                      : `${colors.text[100]} !important`,
                    backgroundColor: active ? `${colors.selected[100]} !important` : undefined,
                    "&:hover": {
                      backgroundColor: `${colors.selected[100]} !important`,
                      color: `${colors.selectedText[100]} !important`,
                    },
                  };
              },
            }}
          >
            {/* Admin details */}
            {!collapsed && (
              <Box pl="12%" pb="7px" borderBottom="0.5px solid #fcfcfc">
                <Box textAlign="left">
                  {/* <Typography variant="h6" color={colors.greenAccent[500]}>
                    {admin?.email}
                  </Typography> */}
                  {/* <Typography variant="h6" color={colors.greenAccent[500]}>
                    {admin?.role[0].role_title}
                  </Typography> */}
                </Box>
              </Box>
            )}

            {/* menu Items */}
            <Box mt="1px">
              {links.map((m,i) => (
                <Links
                key={i}
                  title={m.name}
                  to={m.to}
                  icon={m.icon()}
                  location={location}
                  collapsed={collapsed}
                  submenu={m.subMenu}
                />
              ))}
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
    </div>
  );
}

export default Sidebar;
